$(document).ready(function () {
  initializeDropdown();
});

function initializeDropdown() {
  $(".dropdownToggle").on("click", showHideDropdown);
  $("body").on("click", clickOutCloseMenu);
}

function showHideDropdown(e) {
  var container = $(this).parent(".dropdown");
  var dropdownMenu = $(this).next(".dropdown-menu");

  e.preventDefault();

  if (container.hasClass("open")) {
    container.removeClass("open open-left open-center");
    dropdownMenu.removeAttr("style");
  } else {
    $(".dropdown").removeClass("open");
    container.addClass("open");

    var menuLeftOffset = dropdownMenu.offset().left;
    var menuWidth = dropdownMenu.outerWidth();
    var containerLeftOffset = container.offset().left;
    var containerWidth = container.outerWidth();
    var openLeftRightOffset = containerLeftOffset + menuWidth;
    var centerLeftPos = -(menuWidth / 2 - containerWidth / 2);

    if (menuLeftOffset < 0) {
      if (openLeftRightOffset > $(window).width()) {
        container.addClass("open-center");
        dropdownMenu.css({ left: centerLeftPos });
      } else {
        container.addClass("open-left");
      }
    }
  }
}

function clickOutCloseMenu(e) {
  if (
    (!$(e.target).hasClass("dropdownToggle") &&
      $(e.target).parents(".dropdown").length == 0) ||
    $(e.target).hasClass("modalTriggerOpen")
  ) {
    $(".dropdown").removeClass("open open-left open-center");
    $(".dropdown-menu").removeAttr("style");
  }
}
